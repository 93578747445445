import React, {useMemo, useContext} from 'react';
import {MediaQueryContext} from './MediaQueryProvider';
import {resolveMQ} from './Utility';
import {ColorModeContext, ColorModeProvider} from './ColorModeProvider';
import {useMediaQuery} from 'src/modules/auth/hooks/useMediaquery';

const ThemeContext = React.createContext();

const resolveTheme = (components, theme) => {
  let resolvedTheme = {};
  for (let name in components) {
    let componentTheme = components[name];
    if (typeof componentTheme === 'function') {
      componentTheme = componentTheme({theme});
    }
    resolvedTheme[name] = componentTheme;
  }
  return resolvedTheme;
};

const resolveBaseTheme = ({base, colorMode}) => {
  if (!colorMode) {
    return base;
  }
  let {
    colors,
    lightColors,
    darkColors,
    shadows,
    lightShadows,
    darkShadows,
    ...restTheme
  } = base;
  if (colorMode === 'dark') {
    if (darkColors) {
      colors = darkColors;
    }
    if (darkShadows) {
      shadows = darkShadows;
    }
  } else if (colorMode === 'light') {
    if (lightColors) {
      colors = lightColors;
    }
    if (lightShadows) {
      shadows = lightShadows;
    }
  }
  return {
    ...restTheme,
    colors,
    shadows,
  };
};

const getResolvedTheme = ({
  base,
  components,
  views,
  viewsLayout,
  componentsLayout,
  colorMode,
  mq,
}) => {
  let theme = resolveBaseTheme({base, colorMode});
  if (components) {
    theme.components = resolveMQ(
      resolveTheme(components, theme),
      base.breakpoints,
      mq.current,
    );
  }
  if (views) {
    theme.views = resolveMQ(
      resolveTheme(views, theme),
      base.breakpoints,
      mq.current,
    );
  }
  if (viewsLayout) {
    theme.viewsLayout = resolveMQ(
      resolveTheme(viewsLayout, theme),
      base.breakpoints,
      mq.current,
    );
  }
  if (componentsLayout) {
    theme.componentsLayout = resolveMQ(
      resolveTheme(componentsLayout, theme),
      base.breakpoints,
      mq.current,
    );
  }
  return theme;
};

let ThemeWithColorModeProvider = ({children, ...props}) => {
  const {base, components, views, viewsLayout, componentsLayout} = props;
  const mq = useContext(MediaQueryContext);
  const colorMode = useContext(ColorModeContext).colorMode;
  const providerValue = useMemo(
    () =>
      getResolvedTheme({
        base,
        components,
        views,
        viewsLayout,
        componentsLayout,
        colorMode,
        mq,
      }),
    [base, components, views, viewsLayout, componentsLayout, colorMode, mq],
  );
  return (
    <ThemeContext.Provider value={providerValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export const ThemeProvider = props => {
  return (
    <ColorModeProvider>
      <ThemeWithColorModeProvider {...props} />
    </ColorModeProvider>
  );
};

export const useTheme = name => {
  const theme = useContext(ThemeContext) || {};
  return name ? theme[name] : theme;
};

export const useMQ = props => {
  const {current, breakpoints} = useContext(MediaQueryContext);
  return resolveMQ(props, breakpoints, current);
};

export const useStyles = styles => {
  const theme = useTheme() || {};
  const {isMobile} = useMediaQuery();
  let resolvedTheme =
    typeof styles === 'function' ? styles({theme, isMobile}) : styles;
  return useMQ(resolvedTheme);
};

export const useComponentTheme = name => {
  const theme = useContext(ThemeContext) || {};
  const componentsTheme = theme.components || {};
  return name ? componentsTheme[name] : theme;
};

export const useViewTheme = name => {
  const theme = useContext(ThemeContext) || {};
  const viewsTheme = theme.views || {};
  return name ? viewsTheme[name] : theme;
};

export const useViewLayout = name => {
  const theme = useContext(ThemeContext) || {};
  const viewsLayout = theme.viewsLayout || {};
  return name ? viewsLayout[name] : theme;
};

export const useComponentLayout = name => {
  const theme = useContext(ThemeContext) || {};
  const componentsLayout = theme.componentsLayout || {};
  return name ? componentsLayout[name] : theme;
};
