import {View} from '@unthinkable/react-core-components';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import images from '../../../assets/images';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import {ColumnTextRender, GroupRowText} from '../styles/CareTeamMembers.style';
import {Button} from '../../../components/button/Button.js';
import buttonType from '../../../constant/buttonType.js';
import {useInvoke} from '../../../hooks/useInvoke';
import {Confirm} from '../../../components/confirm';
import {routeLink} from '../../../constant';
import {Icon} from '../styles/CareTeamMembers.style';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import modalMessages from '../../../constant/ModalMessage';
import {action, entity} from '../../../constant/authorization';
import {STATUS} from '../../user/constants/UserConstants';
import checkAuthorization, {
  checkPrimaryPractice,
} from '../../../services/accessService';
import {isAdmin} from '../../../utils/checkUserType';
import {
  patientDetailsWidgetStyle,
  patientDetailsWidgetStyleForMobile,
} from './style/PatientDetail.style';
import {useMediaQuery} from '../../../modules/auth/hooks/useMediaquery';

const RenderUser = ({row}) => {
  return (
    <View>
      <ColumnTextRender title={row.user.name}>{row.user.name}</ColumnTextRender>
      <GroupRowText title={row.user.email}> {row.user.email} </GroupRowText>
    </View>
  );
};

const RenderDelete = ({row}) => {
  const {user} = useAuth();
  const deleteDocument = useInvoke({
    method: 'put',
    eventSourceId: ['careTeamMembers'],
  });

  if (
    isAdmin() ||
    (user?.practiceId &&
      user?.practiceId?._id === row?.user?.practiceId?._id) ||
    (user?.facilityId && user?.facilityId?._id === row?.user?.facilityId?._id)
  ) {
    return (
      <Confirm
        title={modalMessages.careTeamMember.title}
        secondaryTitle={row.user.name}
        message={modalMessages.careTeamMember.message}
        confirmText={modalMessages.careTeamMember.confirmText}
        variant="error"
        onConfirm={async () => {
          await deleteDocument({
            uri:
              routeLink.removeCareTeamMember +
              `/${row.patient}/${row.user._id}`,
          });
        }}>
        <Icon src={images.trash} title="Delete" />
      </Confirm>
    );
  } else {
    return null;
  }
};

export const CareTeamMembers = props => {
  const {patient} = props;
  const navigate = useNavigate();
  const {user} = useAuth();
  const {isMobile} = useMediaQuery();
  return (
    <View
      style={
        isMobile
          ? patientDetailsWidgetStyleForMobile
          : patientDetailsWidgetStyle
      }>
      <TableHeader
        title={'Care Team Members'}
        actions={
          checkAuthorization(user, entity.careTeamMembers, action.create) &&
          checkPrimaryPractice(user, patient)
            ? [
                <Button
                  key="addCareTeamMember"
                  text="Add Care Team Member"
                  onPress={() => {
                    navigate(
                      `/patient/details/${patient?._id}/add-care-team-member`,
                      {
                        state: {
                          patient: patient,
                          titleName: patient.FullName,
                        },
                      },
                    );
                  }}
                  icon={images.addUser}
                  iconPosition="right"
                  buttonType={buttonType.linkButtonWithBorder}
                />,
              ]
            : []
        }
      />
      <BasicTable
        variant={'outline'}
        api={routeLink.getCareTeamMember}
        filter={JSON.stringify({
          hidden: {$exists: false},
          patient: patient._id,
          status: STATUS.ACTIVE,
          primaryPractice: patient?.primaryPractice?._id,
          practices: patient?.practices,
          residence: patient?.residence?._id,
          currentLocation: patient?.currentLocation?._id,
        })}
        eventSourceId="careTeamMembers"
        sort={{
          createdAt: -1,
        }}
        fields={{
          user: {
            _id: 1,
            name: 1,
            email: 1,
            userType: {
              name: 1,
            },
            practiceId: {
              name: 1,
            },
            facilityId: {
              name: 1,
            },
            employerType: {
              name: 1,
            },
          },
          patient: 1,
        }}
        columns={[
          {
            header: 'Name',
            render: RenderUser,
          },
          {
            header: 'Role',
            field: 'user.userType.name',
            type: 'text',
          },
          {
            header: 'Employer',
            field: row =>
              row.user.employerType?.name === entity.facilities
                ? row?.user?.facilityId?.name
                : row?.user?.practiceId?.name,
            type: 'text',
          },
          {
            render: RenderDelete,
            width: 50,
            visible: () =>
              checkAuthorization(user, entity.careTeamMembers, action.delete) &&
              checkPrimaryPractice(user, patient),
          },
        ]}
        {...props}
      />
    </View>
  );
};
