import {View} from '@unthinkable/react-core-components';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import images from '../../../assets/images';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import {Icon} from '../../../modules/facilities/screens/FacilityTable.style';
import {Button} from '../../../components/button/Button.js';
import buttonType from '../../../constant/buttonType.js';
import {useInvoke} from '../../../hooks/useInvoke.js';
import routeLink from '../../../constant/routeLink.js';
import {Confirm} from '../../../components/confirm/index.js';
import Config from '../../../Config';
import {CareTeamMembers} from './CareTeamMembers';
import {TCMEncounters} from '../components';
import {ChatMessageAreaContainer} from '../../../modules/chat/styles/ChatMessageArea.style';
import ChatMessageArea from '../../../modules/chat/components/ChatMessageArea';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {toasterMessage} from '../../../constant';
import {useToast} from '@unthinkable/react-toast';
import {DEFAULT_LIMIT} from '../constants/PatientConstants';
import {useDispatch} from 'react-redux';
import {fetchDataRequest} from '../../../modules/chat/chatRedux/actions';
import {FilePreviewer} from '../../../components/filePreviewer';
import {
  PatientHeader,
  OutPatientMedication,
  OutPatientDiagnose,
} from '../components/index';
import {patientDetailStyles} from './style';
import {getMembersData} from '../../../modules/chat/chatRedux/reducer';
import moment from 'moment';
import {
  patientDetailsWidgetStyle,
  patientDetailsWidgetStyleForMobile,
} from './style/PatientDetail.style';
import {getPatientsFullName} from '../../../utils/commonMethod';
import {useMediaQuery} from '../../../modules/auth/hooks/useMediaquery';

const RenderDelete = ({row}) => {
  const deleteDocument = useInvoke({
    method: 'delete',
    eventSourceId: ['patient-documents'],
  });
  return (
    <Confirm
      title="Delete"
      secondaryTitle={row.name}
      message="Are you sure you want to delete this Document?"
      confirmText="Delete"
      variant="error"
      onConfirm={async () => {
        await deleteDocument({
          uri: routeLink.patientDocuments + '/' + row._id,
        });
      }}>
      <Icon src={images.trash} />
    </Confirm>
  );
};

const PatientsDocuments = props => {
  const {patient} = props;
  const navigate = useNavigate();
  const {isMobile} = useMediaQuery();
  return (
    <View
      style={
        isMobile
          ? patientDetailsWidgetStyleForMobile
          : patientDetailsWidgetStyle
      }>
      <TableHeader
        title={'Documents'}
        actions={[
          <Button
            key="addPractice"
            text="Add File"
            onPress={() => {
              navigate('/patient/details/add-documents', {
                state: {
                  patient,
                  titleName: patient.FullName,
                },
              });
            }}
            icon={images.Upload}
            iconPosition="right"
            buttonType={buttonType.linkButtonWithBorder}
          />,
        ]}
      />
      <BasicTable
        variant={'outline'}
        api="/v1/patientDocuments"
        filter={{
          patientId: patient._id,
        }}
        eventSourceId="patient-documents"
        sort={{
          createdAt: -1,
        }}
        columns={[
          {
            header: 'File Name',
            field: 'name',
            type: 'text',
          },
          {
            header: 'Uploaded On',
            field: 'createdAt',
            type: 'date',
            formatOptions: {
              format: Config.dateTimeFormat,
            },
            width: 200,
          },
          {
            render: FilePreviewer,
            width: 50,
            field: 'file',
          },
          {
            render: RenderDelete,
            width: 50,
          },
        ]}
        {...props}
      />
    </View>
  );
};

const PatientChatMessages = props => {
  // variables
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const {user} = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const chatElement = useRef(null);
  const dispatch = useDispatch();
  const {isMobile} = useMediaQuery();

  const {PNGroupId: channelId, _id: groupId} = props?.patient?.groupId || {};
  const {FirstName, LastName, MiddleName, DOB} = props?.patient || {};
  const membersData = getMembersData(groupId);
  const groupName = getPatientsFullName({
    FirstName,
    LastName,
    MiddleName,
    Render: false,
  });

  // functions
  const scrollToBottom = () => {
    chatElement.current.scrollTo(0, chatElement.current.scrollHeight, {
      animated: true,
    });
  };

  const getMessageHistory = async () => {
    try {
      let options = {
        count: DEFAULT_LIMIT,
      };
      const channel = await user?.chatInstance?.getChannel(channelId);
      const history = await channel?.getHistory(options);
      const messages = history?.messages?.map(message => ({
        text: message?.content[0]?.text,
        timetoken: message?.timetoken,
        uuid: message?.userId,
      }));
      return messages;
    } catch (error) {
      toast({
        message: `${toasterMessage.getMessage.failure}: ${error}`,
        type: 'error',
      });
    }
  };
  // useEffects
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        dispatch(fetchDataRequest(groupId));
        const message = await getMessageHistory({});
        setMessages(message || []);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast({
          message: `${toasterMessage.getMessage.failure}: ${error}`,
          type: 'error',
        });
      }
    };
    if (user) {
      fetchData();
    }
  }, [channelId, user]);

  useEffect(() => {
    if (user?.chatInstance) {
      user.chatInstance.sdk.subscribe({channels: [channelId]});
      const chatListener = {
        message: message => {
          const sentMessage = {
            text: message.message[0].text,
            sender: user.name,
            uuid: message.message[0].uuid,
            timetoken: message.timetoken,
          };

          setMessages(prevMessages => [...prevMessages, sentMessage]);
        },
      };
      user.chatInstance.sdk.addListener(chatListener);
    }
  }, [channelId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // return
  return (
    <View
      style={
        isMobile
          ? {}
          : {
              flex: 1,
              overflow: 'hidden',
              maxHeight: '335px',
            }
      }>
      <TableHeader
        title={'Chat Messages'}
        actions={[
          <Button
            key="exploreChat"
            onPress={() => {
              navigate(`/patient/chat/${channelId}/${groupId}`, {
                state: {
                  group: {
                    name:
                      groupName +
                      ' ' +
                      moment(DOB).utc().format(Config.dateFormat),
                    _id: groupId,
                  },
                },
              });
            }}
            icon={images.expand}
            buttonType={buttonType.linkButtonWithBorder}
          />,
        ]}
      />
      <ChatMessageAreaContainer ChatType={'patient'}>
        <ChatMessageArea
          isLoading={isLoading}
          messages={messages}
          membersData={membersData}
          chatElement={chatElement}
        />
      </ChatMessageAreaContainer>
    </View>
  );
};

export const PatientDetails = () => {
  const {
    state: {patient},
  } = useLocation();
  const {user} = useAuth();
  const {isMobile} = useMediaQuery();
  return (
    <View style={patientDetailStyles.viewStyles.container}>
      <PatientHeader patient={patient} patientDetail={true} />
      <View style={patientDetailStyles.viewStyles.contentContainer}>
        <View
          style={
            isMobile
              ? patientDetailStyles.viewStyles.column
              : patientDetailStyles.viewStyles.row
          }>
          <CareTeamMembers patient={patient} />
          <TCMEncounters
            patient={patient}
            sort={{createdAt: -1}}
            api={routeLink.getTCMEncounter}
            fields={{
              admissionDate: 1,
              dischargeDate: 1,
              currentLocation: {
                name: 1,
              },
              status: 1,
              taskInfo: {
                _id: 1,
                taskId: 1,
                status: 1,
                successStatus: 1,
                type: 1,
                tcmDueDate: 1,
                nextTcmDueDate: 1,
              },
              tcmNotes: 1,
              diagnoseReason: 1,
              patientClass: 1,
              latestEventLocation: {
                name: 1,
              },
              ContactTaskDuration: 1,
              MedicationTaskDuration: 1,
              VisitTaskDuration: 1,
            }}
            filter={{
              patientId: patient._id,
            }}
          />
        </View>
        <View
          style={
            isMobile
              ? patientDetailStyles.viewStyles.column
              : patientDetailStyles.viewStyles.row
          }>
          <OutPatientDiagnose patient={patient} />
          <OutPatientMedication patient={patient} />
        </View>
        <View
          style={
            isMobile
              ? patientDetailStyles.viewStyles.column
              : patientDetailStyles.viewStyles.row
          }>
          <PatientsDocuments patient={patient} />
          {user?.chatAuthKey && <PatientChatMessages patient={patient} />}
        </View>
      </View>
    </View>
  );
};
