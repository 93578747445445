export const patientDetailStyles = {
  headerContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerItemContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: 8,
  },
  headerButtonContainer: {
    flexDirection: 'row',
    gap: 8,
  },
  mr10: {
    marginRight: 10,
  },
  styles: {
    container: {
      flex: 1,
      flexDirection: 'column',
      overflowY: 'auto',
      gap: 18,
    },
    row: {
      flexDirection: 'row',
      gap: 36,
    },
    column: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      gap: 36,
    },
  },
  viewStyles: {
    container: {
      flex: 1,
      flexDirection: 'column',
      gap: 4,
      overflowY: 'hidden',
    },
    row: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      gap: 36,
    },
    column: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      gap: 36,
    },
    tableContainer: {
      flex: 1,
      overflow: 'auto',
      scrollbarWidth: 'none',
      gap: 8,
    },
    contentContainer: {
      flex: 1,
      overflow: 'auto',
      scrollbarWidth: 'none',
      gap: 18,
    },
  },
};
export const patientDetailsWidgetStyle = {
  flex: 1,
  overflow: 'hidden',
  maxHeight: '335px',
};

export const patientDetailsWidgetStyleForMobile = {
  maxHeight: '335px',
};
